import { memo, useMemo } from 'react';
import cn from 'classnames';
import Image from 'next/image';
import { Box } from 'uikit/Box';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import classes from './Error500.module.scss';

export const Error500 = memo(() => {
  const theme = useAppSelector(themeSelector);
  const workerIcon = useMemo(() => `/icons/worker_${theme}.png`, [theme]);
  return (
    <Box className={cn(classes.wrap, classes[theme])}>
      <Box className={classes.inner} alignItems="center" justifyContent="center">
        <Box className={classes.worker}>
          <Image
            className={classes.img}
            layout="fill"
            objectFit="cover"
            src={workerIcon}
            alt="worker"
          />
        </Box>
        <Box className={classes.textBlock} direction="column">
          <Box className={classes.textTop}>
            Unavailable
          </Box>
          <Box className={classes.textBottom}>
            Marketplace is temporarily unavailable, we are working on fixing it
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default Error500;
